<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">标签内容管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="标签名称" class="searchboxItem ci-full">
              <span class="itemLabel">标签名称:</span>
              <el-input
                  v-model="labelName"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入标签名称"
              />
            </div>
            <el-button class="bgc-bv" round @click="getTagList">查询</el-button>
            <el-button class="bgc-bv" round @click="add()">添加标签</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="labelGather">
              <div class="gather" v-for="(item,index) in colorList" :key="index">
                <div class="gatherInner">
                  <p v-for="(itemInner,indexInner) in item.taglist" :key="indexInner">
                    {{ itemInner.labelName }}
                    <el-button type="text" size="small" icon="el-icon-edit" title="编辑" @click="hanldeEdit(itemInner)"></el-button>
                    <el-button type="text" size="small" icon="el-icon-delete" title="删除" @click="hanldeDelete(itemInner.recruitmentLabelId)"></el-button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="doClose"
        :close-on-click-modal="false">
      <el-form ref="form" :model="formData" label-width="20px" size="small" :rules="rules">
        <el-form-item label="" prop="labelName" class="form-item">
          <span class="labelColor" :style="'background-color:'+ currentColor"></span>
          <el-input
              v-model="formData.labelName"
              placeholder="请输入标签名称"
              maxlength="5"
              show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span class="colorPointBox">
          <span @click="clickColorPoint(item)" v-for="(item,index) in colorList" :key="index" :class="item.active?'colorPoint colorPoint-active':'colorPoint'" :style="'background-color:'+item.tagColor "></span>
        </span>
        <el-button type="primary" @click="createTag">创建标签</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "labelContentList",
  components: {
    // Empty,
    // PageNum
  },
  data() {
    return {
      labelName: '',


      dialogVisible:false,
      currentColor: '#02A7F0',
      currentId: '',
      formData: {
        labelName: '',
      },
      rules: {
        labelName:[{required: true, message: '标签名称不能为空', trigger: 'blur'}],
      },
      colorList: []
    };
  },
  computed: {},
  mounted() {
    this.getTagList()
  },
  methods: {
    // 清除掉每一组里的标签
    initList(){
      this.colorList = [
        {
          tagColor: '#02A7F0',
          active: true,
          taglist: []
        },
        {
          tagColor: '#EC808D',
          active: false,
          taglist: []
        },
        {
          tagColor: '#F59A23',
          active: false,
          taglist: []
        },
        {
          tagColor: '#FACD91',
          active: false,
          taglist: []
        },
        {
          tagColor: '#67C671',
          active: false,
          taglist: []
        },
        {
          tagColor: '#C280FF',
          active: false,
          taglist: []
        },
        {
          tagColor: '#D7D7D7',
          active: false,
          taglist: []
        },
      ]
    },
    getTagList() {
      let _this = this
      this.initList()
      let param = {}
      if(this.labelName){
        param.labelName = this.labelName
      }
      this.$post("/hr/recruitment/label/show",param)
          .then((res) => {
            for (const key in res.data) {
              console.log(key,res.data[key])
              _this.colorList.forEach((e,i)=>{
                if(e.tagColor == key.toUpperCase()){
                  e.taglist = res.data[key]
                }
              })
            }
          })
          .catch(() => {
            return;
          });


    },
    // 新增
    add() {
     this.dialogVisible = true
    },
    hanldeEdit(item){
      this.currentId = item.recruitmentLabelId
      this.currentColor = item.colour
      this.formData.labelName = item.labelName
      this.dialogVisible = true
    },
    // 创建标签
    createTag(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(this.currentColor,this.formData.labelName)
          let param = {
            colour: this.currentColor,
            labelName:this.formData.labelName
          }
          let url = "/hr/recruitment/label/insert"
          if(this.currentId){
            url = "/hr/recruitment/label/modify"
            param.recruitmentLabelId = this.currentId
          }
          this.$post(url,param)
              .then((res) => {
                if(res.status == 0){
                  this.$message({
                    type: "success",
                    message: "创建成功！",
                  });
                  this.getTagList()
                  this.doClose()
                }
              })
              .catch(() => {
                return;
              });

        }
      })
    },
    doClose() {
      this.currentId = ''
      this.currentColor = '#02A7F0'
      this.colorList.forEach((e,i)=>{
        e.active = false
        if(i == 0){
          e.active = true
        }
      })
      this.formData = {
        labelName: ''
      }
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    hanldeDelete(id) {
      this.$confirm("你确定要删除该标签吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then(() => {
            this.$post("/hr/recruitment/label/remove", { recruitmentLabelId: id }).then(res => {
              if (res.status == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
                this.getTagList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
    },
    // 点击色块
    clickColorPoint(item){
      this.currentColor = item.tagColor
      this.colorList.forEach((e,i)=>{
        e.active = false
        if(e.tagColor == item.tagColor){
          e.active = true
        }
      })
    }
  }
  //   beforeRouteLeave:resetKeepAlive
};
</script>
<style lang="less" scoped>
.labelGather{
  display: flex;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow-x: scroll;
  .gather{
    position: relative;
    flex: 1;
    max-height: 100%;
    min-width: 212px;
    margin: 20px 10px 10px 0;
    background-color: #f1f1f1;
    .gatherInner{
      min-height: 100%;
      max-height: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 0; /*高宽分别对应横竖滚动条的尺寸*/
        height: 0;
      }
    }
    p{
      font-size: 14px;
      line-height: 32px;
      padding: 0 52px 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      /deep/.el-button--text{
        font-size: 16px;
        display: none;
        width: 26px;
        position: absolute;
        top: 0;
        border: 0;
        &:nth-child(1){
          color: #e6a23c;
          right: 26px;
        }
        &:nth-child(2){
          color: #f00;
          right: 0;
        }
      }

      &:hover{
        /deep/.el-button--text{
          display: block;
        }
      }
    }
    &::before{
      content: '';
      position: absolute;
      width: 100%;
      top: -20px;
      left: 0;
      border-top-width: 10px;
      border-top-style: solid;
    }
    &:nth-child(1){
      color: #02A7F0;
      &::before{
        border-top-color: #02A7F0;
      }
    }
    &:nth-child(2){
      color: #EC808D;
      &::before{
        border-top-color: #EC808D;
      }
    }
    &:nth-child(3){
      color: #F59A23;
      &::before{
        border-top-color: #F59A23;
      }
    }
    &:nth-child(4){
      color: #FACD91;
      &::before{
        border-top-color: #FACD91;
      }
    }
    &:nth-child(5){
      color: #67C671;
      &::before{
        border-top-color: #67C671;
      }
    }
    &:nth-child(6){
      color: #C280FF;
      &::before{
        border-top-color: #C280FF;
      }
    }
    &:nth-child(7){
      color: #666;
      &::before{
        border-top-color: #D7D7D7;
      }
    }
  }
}
/deep/.el-form-item--small.el-form-item{
  position: relative;
}
.labelColor{
  position: absolute;
  top: 11px;
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.dialog-footer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.colorPoint{
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  margin-right: 12px;
  cursor: pointer;
  &::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 12px;
    border: 2px solid #dfdfdf;
  }
}
.colorPoint-active{
  &::before{
    border: 2px solid #409EFF;
  }
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__headerbtn{
  top: 20px;
}
/deep/.el-dialog__header .el-dialog__headerbtn .el-dialog__close{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  border-color: #333;
}
</style>

